import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      // apiUrl: "https://localhost:7123/api",
      // apiUrl: "https://r3backendstg.azurewebsites.net/api",
      apiUrl: "https://r3backendprd.azurewebsites.net/api",

      appVersion:"2.24.123",

      // Add map configuration
      mapbox: {
        // To be replaced with a r3mote.io Mapbox access token
        accessToken: 'pk.eyJ1Ijoic2FtbXltYmIiLCJhIjoiY20zNHh6YXprMDNkbDJpcHk5Y2I0dmluayJ9.4c5UcG0DtdwycT_6C0TlWw',
        defaultCenter: [53.5461, -113.4937], // Edmonton area
        defaultZoom: 5,
        theme: {
          baseColor: '#1a1a1a',  // Match app dark theme
          textColor: '#ffffff',
          borderColor: '#404040'
        }
      }
    };
  },
  mutations,
  actions,
  getters,
};
