export  default {
    getProperties(state) {
        return state.properties;
    },
    getHeaderProperties(state){
        var containerTypeId = 1;    
        // console.log("getHeaderProperties" )
        // console.log(state.properties.filter(x=> x.showHeader && x.appliesTo=="C" && x.containerTypeId == containerTypeId))
        return state.properties.filter(x=> x.showHeader && x.appliesTo=="C" && x.containerTypeId == containerTypeId);
    },
    getPropertyLists(state) {
        return state.propertyLists;
    },
    getCurrentPropertyList(state) {
        return state.propertyList;
    },
    getResponseCode(state) {
        return state.responseCode;
    }
};