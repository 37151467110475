import { createStore } from 'vuex';

import config from './modules/config/index.js';
import auth from './modules/auth/index.js';
import containersModule from './modules/containers/index.js';
import customers from './modules/customers/index.js';
import areas from './modules/areas/index.js';
import regions from './modules/regions/index.js';
import divisions from './modules/divisions/index.js';
import manufacturers from './modules/manufacturers/index.js';
import containerTypes from './modules/containerTypes/index.js';
import operators from './modules/operators/index.js';
import devices from './modules/devices/index.js';
import deviceTypes from './modules/deviceTypes/index.js';
import deviceStatuses from './modules/deviceStatuses/index.js';
import users from './modules/users/index.js';
import groups from './modules/groups/index.js';
import permAreas from './modules/permissionsAreas/index.js';
import permOperators from './modules/permissionsOperators/index.js';
import permContainerGroups from './modules/permissionsContainerGroups/index.js';
import properties from './modules/properties/index.js';
import menu from './modules/menu/index.js';
import utils from './modules/utils/index.js';
import sites from './modules/utils/index.js';
import usage from './modules/usage/index.js';
import offices from './modules/offices/index.js';
import districts from './modules/districts/index.js';
import logInjections from './modules/logInjections/index.js';
import logRequests from './modules/logRequests/index.js';
import userState from './modules/userState/index.js';
import noteTypes from './modules/noteTypes/index.js';
import contracts from './modules/contracts/index.js';

const store = createStore({
    modules: {
        config: config,
        auth: auth,
        containers: containersModule,
        areas: areas,
        regions: regions,
        divisions: divisions,
        manufacturers: manufacturers,
        containerTypes: containerTypes,
        operators: operators,
        devices: devices,
        deviceTypes: deviceTypes,
        deviceStatuses: deviceStatuses,
        users: users,
        groups: groups,
        permAreas: permAreas,
        permOperators: permOperators,
        permContainerGroups: permContainerGroups,
        customers: customers,
        properties: properties,
        menu: menu,
        sites: sites,
        usage: usage,
        utils: utils,
        offices: offices,
        districts: districts,
        logInjections: logInjections,
        logRequests: logRequests,
        userState: userState,
        noteTypes: noteTypes,
        contracts: contracts
    }
});

export default store;