<template>
  <DxToast v-model:visible="noteVis" :message="noteMsg" :type="noteType">
    <DxPosition my="top" at="top" offset="0 100" />
  </DxToast>
</template>

<script setup>
/* eslint-disable no-unused-vars*/
import { ref, onMounted, computed, watch, defineProps, defineEmits } from 'vue'
import { DxToast, DxPosition } from 'devextreme-vue/toast'
/* eslint-enable no-unused-vars*/

const props = defineProps({
  id: Number,
  msg: String,
  type: String,
})
const noteVis = ref(false)
const noteType = ref('')
const noteMsg = ref('')

watch(
  () => props.id,
  /* eslint-disable-next-line no-unused-vars*/
  (newV, oldV) => {
    console.log("NOTIFICATION WATCHER")
    noteVis.value = true
    noteType.value = props.type
    noteMsg.value = props.msg
  },
)
</script>

<style scoped></style>
