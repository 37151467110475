<template>
  <R3Title title="Reporting ❯ Injection Tank Status v2"></R3Title> <!-- unicode U+276F : ❯ -->
  
  <div class="pageContent">
    <R3ContainerGrid :typeId="typeId" @select-container="openDetails" />
    <R3ContainerPopup :containerId="selectedContainer" />
  </div>
  
</template>

<script>

import R3Title from "@/components/base/page-title.vue";
import R3ContainerGrid from "./container-grid-v2.vue";
// import R3ContainerDetails from "./container-details-v2.vue";
import R3ContainerPopup from "./container-list-desktop-inject-popup-v2.vue"
import { DxPopup } from 'devextreme-vue/popup';

export default {
  components: {
    R3Title,
    R3ContainerGrid,
    // R3ContainerDetails,
    R3ContainerPopup,
    // DxPopup,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      selectedContainer: 0,
      fullContainer: 0,
      detailsVisible: false,
      typeId: 1,
    };
  },
  computed: { },
  methods: {
    async openDetails(key) {
      this.selectedContainer = key;
      console.log("OPEN DETAILS", key)
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    async loadPageData() {
      
    },
  },
};

</script>

<style scoped>
  .pageContent {
    /* max-height: 40vh; */
    /* overflow: scroll; */
  }
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  .subtitle pre {
    white-space: pre-wrap; 
    word-wrap: break-word;
    font-family: inherit;
  }
</style>
