<template>

  <div v-if="container">
    <DxScrollView :width="auto" :height="500" direction="both">  <!-- 'horizontal' | 'vertical' -->

      <div class="divGroup" id ="containerInfo">
        <div class="propHeader">Name</div>
        <div class="propVal">{{this.container.containerName}}</div>
        <div class="propHeader">Operator</div>
        <div class="propVal">{{this.container.operator}}</div>
        <div class="propHeader">Area</div>
        <div class="propVal">{{this.container.area}} </div>
        <div class="propHeader">Device</div>
        <div class="propVal">{{this.container.serialnum}}</div>
        <div v-for="prop in headerProps" :key="prop.propertyId">
          <div class="propHeader">{{prop.propertyName}}</div>
          <!-- <div class="propVal">{{this.container[prop.propertyName]}}</div> -->
          <div class="propVal">{{getHeaderProp(prop.propertyName)}}</div>
        </div>
        <div>
          <!-- <hr style="height:1px;border:1px solid #444;"/> -->
          <div class="propHeader">Coordinates</div>
          <div class="propVal">{{this.container.latitude}} , {{this.container.longitude}}</div>
          <!-- <div class="divMap">
            <R3GPS :container="container" />
          </div> -->
        </div>
      </div>
    </DxScrollView>
  </div>
  <!-- <DxScrollView :width="auto" :height="440" direction="both">  
    <div v-if="statusCritical.length>0" class="divGroup critical property" id ="containerInfo" width = 'auto'>
      <div v-for="prop in statusCritical" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
    <br>
    <div v-if="statusMarginal.length>0" class="divGroup marginal property" id ="containerInfo" width = 'auto'>
      <div v-for="prop in statusMarginal" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
    <br>
    <div v-if="statusGood.length>0" class="divGroup good property" id ="containerInfo" width = 'auto'>
      <div v-for="prop in statusGood" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
  </DxScrollView> -->
  
</template>

<script>

// import { DxScrollView } from "devextreme-vue/scroll-view";
import R3GPS from "@/components/container/container-details-gps.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  components: {
    // DxScrollView
    // R3GPS,
    DxScrollView
  },
  props: ['containerId', 'container'],
  data() {
    return {
    };
  },
  computed: {
    // ❌⚠️✔️
    statusCritical(){
      // console.log(this.container.properties.filter((data) => data.status == "❌"));
      return this.container.properties.filter((data) => data.status == "❌");
    },
    statusMarginal(){
      return this.container.properties.filter((data) => data.status == "⚠️");
    },
    statusGood(){
      return this.container.properties.filter((data) => data.status == "✔️");
    },
    statusUndefined(){
      return this.container.properties.filter((data) => data.status == "�");
    },
    headerProps(){
      if (this.container.containerName == undefined || this.container.containerName == null )
      {
        return [];
      }
      //problem here
      let props = this.$store.getters["properties/getProperties"];
      if (props.length==0 || !props)
      {
        return [];
      }
      let headProps = []
      for (let i = 0; i < props.length; i++) {
        if (props[i].showHeader && props[i].appliesTo == "C" && props[i].containerTypeId == this.container.containerTypeId){
          headProps.push(props[i])
        }
      }  
      return headProps;
    }
  },
  created() {  },
  methods:{
    getHeaderProp(propName){
      // console.log(this.container);
      if (!this.container[propName] || this.container[propName].length==0){
        return '-';
      }
      return this.container[propName];
      
    }
  }
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .divMap {
    border-style: solid;
    border-width: 1px; 
    /* margin: 20px; */
    border-color: rgb(200, 70, 70);
    padding: 5px;
  }
  .critical {
    border-color: rgb(180, 0, 0);
  }
  .marginal {
    border-color: rgb(180, 150, 0);
  }
  .good {
    border-color: rgb(0, 180, 0);
  }
  .unknown {
    border-color: rgb(100, 100, 100);
  }
  .property {
    font-size: 14px;
    font-weight: 100;
    color: rgb(255,255,255,.8);
    /* margin-left: 10px; */
    margin-bottom: 2px;
    /* z-index: 20; */
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.7);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
</style>
