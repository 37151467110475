import common from '../common.js';

export default {
  async deleteContract(context, payload) {
    const newRequest = {
      contractId: payload.contractId,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/contract/${payload.contractId}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');  
      throw error;
    }
    // context.commit('updateDistrict', payload);
  },
  async updateContract(context, payload) {

    const newRequest = {
      contractId: payload.contractId,
      contractName: payload.contractName,
      defaultLength: payload.defaultLength
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/contract`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');  
      throw error;
    }
    context.commit('updateContract', payload);
  },

  async addContract(context, payload) {
    const newRequest = {
      contractId: payload.contractId,
      contractName: payload.contractName,
      defaultLength: payload.defaultLength
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/contract`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }
    
    const responseJson = await response.json();

    payload.contractId = responseJson.contractId;
    context.commit('addContract', payload);
  },

  async loadContracts(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'contract')
    const responseData = [];

    for (const key in responseJson) 
    {
      const contract = {
        contractId: responseJson[key].contractId,
        contractName: responseJson[key].contractName,
        defaultLength: responseJson[key].defaultLength
      };
      responseData.push(contract);
    }
    context.commit('setContracts', responseData)
  },

  async loadContract(context, payload ) 
  {
    const id = payload.id;
    let contract = {
      contractId: 0,
      contractName: "",
      defaultLength: 0
    };

    if (id != 0 && id != undefined)
    {
      const responseJson = await common.getRequest(context, payload, `contract/${id}`)
      contract = {
        contractId: responseJson.contractId,
        contractName: responseJson.contractName,
        defaultLength: responseJson.defaultLength
        };
    }
    context.commit('setCurrentContract', contract)
  },
};
