<template>
  
  <!-- 
  MANUFACTURER
  DEVICE TYPE
  STATUS
  USAGE
  NAME / IMEI
  CONTRACT
  INSTALL DATE
  CONTRACT DATE
  -->

  <div>
    <DxTextBox class="form-field" label="Name / IMEI" v-model:value="deviceName" />    
    <!-- <DxTextBox class="form-field" label="Manufacturer" v-model:value="manufacturer" /> -->
    <DxSelectBox class="form-field" label="Type" :search-enabled="true" :data-source="this.dataDeviceTypes" value-expr="deviceTypeId" display-expr="deviceTypeName" v-model:value="deviceTypeId"/>     
    <!-- <DxTextBox class="form-field" label="Id" v-model:value="deviceStatus" /> -->
    <!-- <DxTextBox class="form-field" label="Id" v-model:value="deviceUsage" /> -->
    <!-- <DxTextBox class="form-field" label="Id" v-model:value="contract" /> -->
    <DxSelectBox class="form-field" label="Contract" :search-enabled="true" :data-source="this.dataContracts" value-expr="contractId" display-expr="contractName" v-model:value="contractId"/>     
    <!-- <DxTextBox class="form-field" label="Install Date" v-model:value="installDate" /> -->
    <!-- <DxDateBox class="form-field"
      label="Contract Date"
      v-model:value="contractDate"
      :input-attr="{ 'aria-label': 'Date' }"
      type="date"
    />  -->
  </div>
  <br><br>
  <hr/>
  <div class="buttons">
    <!-- <DxButton class="saveBtn" :disabled="validated" icon="save" text="Add" @click="addDevice"/> -->
    <DxButton class="saveBtn" icon="save" text="Add" @click="addDevice"/>
    <DxButton class="cancelBtn" text="Cancel" @click="cancelDevice"/>
  </div>
</template>
<script>

import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from 'devextreme-vue/select-box';
import DxButton from 'devextreme-vue/button';
import DxDateBox from 'devextreme-vue/date-box';

export default {
  props: {
    device: String,
  },
  components: {
    DxTextBox,
    DxSelectBox,
    DxButton,
    // DxDateBox
  },
  watch: {
    device: function (newId, oldId) {
      this.deviceName = "";
      this.deviceTypeId = 0;
      this.contractId = 0;
      // this.contractDate= null
    }
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      dataContracts: [],
      dataDeviceTypes: [],
      dataDevice: null,
      deviceName: "",
      deviceTypeId: 0,
      contractId: 0,
      // contractDate: null

    };
  },
  computed: { 
    validated(){
      var valid = true;
      if (this.deviceName.length == 0) valid=false;
      if (this.deviceTypeId.length == 0) valid=false;
      if (this.contractId.length == 0) valid=false;
      
      return valid;
    }
  },
  methods: {
    addDevice(){
      if (!this.validated)
        alert('Enter valid data!')
      else{
        var dev = {
          deviceName: this.deviceName,
          deviceTypeId: this.deviceTypeId,
          contractId: this.contractId,
          // contractDate: this.contractDate
        }
        this.$emit('deviceAdded', dev);
      }
      
    },
    cancelDevice(){
      this.$emit('deviceCancelled');
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      this.deviceName= null
      this.deviceTypeId= null
      this.contractId= null
      // this.contractDate= null
      try {
        await this.$store.dispatch("contracts/loadContracts");
        this.dataContracts = await this.$store.getters["contracts/getContracts"];
        await this.$store.dispatch("deviceTypes/loadDeviceTypes");
        this.dataDeviceTypes = await this.$store.getters["deviceTypes/getDeviceTypes"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }   
      this.loadingVisible = false;
    },
  },
};
</script>

<style scoped>
  .form-field{
    margin: 30px 0 0 0;
  }
  hr {
    height:1px;
    border:1px solid #444;
  }
  .buttons {
    width: 200px;
    margin: 0 auto;
    display: inline;
  }
  .saveBtn {  
    color: black;  
    background-color: rgb(255, 115, 0);  
    /* margin: 0 20px 20px 0; */
    margin: 0  10px auto;
    width: 100px;
    display: inline-block;
    /* float: left; */
  } 
  .cancelBtn {  
    color: black;  
    background-color: rgb(255, 255, 255,0.3);  
    /* margin: 0 20px 20px 0; */
    margin: 0 auto;
    width: 100px;
    display: inline-block;
    /* float: right; */
    /* display: flex; */
    /* justify-content: flex-end; */
  } 
</style>
