<template>
  <div >
    <!-- History, Injection w/Target, last 5 fills, 24/48/72 -->
    <div v-if="loading">
      <DxLoadPanel
        :visible="true"
        :show-indicator="true"
        :show-pane="true"
        :shading="false"
        :hide-on-outside-click="false"
      />
    </div>
    <div v-else>
      <DxScrollView :width="auto" :height="440" direction="both">  
        
        <div class="divGroup" id ="containerInfo">
          <!-- <div class="propThreshold" style="text-align: right; margin-top: -5px">
            <DxSwitch v-model:value="volViewDetail" @value-changed="changeVolView" />
          </div> -->
          <!-- <div v-if="volViewDetail">
            <DxChart class="chartGroup" :data-source="containerVolumeHistoryDetail" >
              <DxCommonSeriesSettings
                argument-field="historyDate"
                value-field="propValue"
                type="line"
                show-in-legend="false"
                color="#9090FF"
              />
              <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                item-text-position="bottom"
                visible="true"
              />
              <DxSeriesTemplate name-field="containerName" visible="false" />
              <DxExport :enabled="true" />
              <DxTitle text="Volume History Hourly" />
              <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
              <DxValueAxis show-zero="true" />  
            </DxChart>
          </div> -->
          <!-- <div v-else> -->
          <div>
            <DxChart class="chartGroup" :data-source="containerVolumeHistory" >
              <!-- <DxZoomAndPan argument-axis="both" value-axis="none"/> -->
              <DxCommonSeriesSettings
                argument-field="historyDate"
                value-field="propValue"
                type="line"
                show-in-legend="false"
                color="#9090FF"
              />
              <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                item-text-position="bottom"
                visible="true"
              />
              <DxSeriesTemplate name-field="containerName" visible="false" />
              <DxExport :enabled="true" />
              <DxTitle text="Volume History Daily">
                <!-- <DxSubtitle text="(Volume L)" /> -->
              </DxTitle>
              <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
              <DxValueAxis show-zero="true" />  
            </DxChart>
          </div>
        </div>

        <div class="divGroup" id ="containerInfo">
          <!-- INJECTION -->
          <DxChart class="chartGroup" :data-source="containerInjectionHistory" >
            <DxCommonSeriesSettings
              argument-field="historyDate"
              value-field="propValue"
              type="bar"
              show-in-legend="false"
              color="#66b5ba"
            />
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="bottom"
              visible="true"
            />
            <DxSeriesTemplate name-field="containerName" visible="false" />
            <DxExport :enabled="true" />
            <DxTitle text="Injection History">
              <!-- <DxSubtitle text="(Injection)" /> -->
            </DxTitle>
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
            <DxValueAxis show-zero="true">  
              <DxStrip :start-value="targetInjection" :end-value="targetInjection*1.03" color="#E6F542" />
            </DxValueAxis>
          </DxChart>
        </div>

        <!-- FILLS BY DATE-->
        <!-- <div class="divGroup" id ="containerInfo">
          <DxChart class="chartGroup" :data-source="containerFillHistory" >
            <DxCommonSeriesSettings
              argument-field="historyDate"
              value-field="propValue"
              type="bar"
              show-in-legend="false"
              color="#ffaa66"
            />
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="bottom"
              visible="true"
            />
            <DxSeriesTemplate name-field="containerName" visible="false" />
            <DxExport :enabled="true" />
            <DxTitle text="Fill History" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
          </DxChart>
        </div> -->
        
        <!-- FILLS LAST 5 EXTENDED-->
        <div class="divGroup" id ="containerInfo">
          <DxChart class="chartGroup" :data-source="containerHistoryExtended" >
            <DxSeries
              argument-field="index"
              value-field="fillAmt"
              :name="container.containerName"
              type="bar"
              color="#ffaa66"
            />
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="bottom"
              visible="true"
            />
            <DxExport :enabled="true" />
            <DxTitle text="Fill History" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
          </DxChart>
        </div>

      </DxScrollView>
    </div>
  </div>
  
</template>

<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxChart, 
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxSeries,
  // DxZoomAndPan,
  DxStrip
} from "devextreme-vue/chart";
import { DxSwitch } from 'devextreme-vue/switch';

export default {
  components: {
    DxLoadPanel,
    DxScrollView,
    DxChart, 
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxLegend,
    DxTitle,
    // DxSubtitle,
    DxTooltip,
    DxValueAxis,
    DxSeries,
    DxExport,
    DxStrip,
    // DxSwitch
  },
  props: ['containerId', 'container'],
  created() { 
    //first time it's opened it calls CREATED, other times it uses the WATCH
    this.loadHistory();
  },
  data() {
    return {
      searchVal: "",
      ctrlOver: "",
      volViewDetail: false ,
      volViewLbl: "Summary",
      loading: true,
    };
  },
  computed: {
    targetInjection(){
      return this.container['Injection Target'];
      // return 2000;
    },
    containerVolumeHistory(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
    containerVolumeHistoryDetail(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerHistoryDetail"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);
      
      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate >= dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate,
            // historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2) + " " + data[key].historyDate.getDate().slice(-8),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
    containerInjectionHistory(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Injection Avg" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }      
      return volumeHist;
    }, 
    containerFillHistory(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const fillHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        // if (data[key].propertyName==="Injection" && parseFloat(data[key].propValue)<0 && data[key].historyDate>=dateCriteria)
        if (data[key].propertyName==="Injection" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            // propValue: parseFloat(data[key].propValue)*-1,
            propValue: ((parseFloat(data[key].propValue)<0) ? parseFloat(data[key].propValue)*-1 : 0),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          fillHist.push(hist);
        }
      }      
      return fillHist;
    }, 
    containerHistoryExtended() {
      
      var data = this.$store.getters["containers/getContainerExtendedHistory"];
      
      if (data != undefined && data != null)
      {
        data = data.slice(0,5);

        for(let i=0;i<data.length;i++)
        {
          // console.log ("PRE",data[i]);
          data[i].index = 'Fill ' + data[i].historyDate;
          data[i].fillAmt = Number(data[i].propValue);
          // console.log ("POST",data[i]);
        }
          
    
        // console.log("HISTEXT");
        // console.log(data);
        return data;
      }
    
      // return this.$store.getters["containers/getContainerExtendedHistory"];
      
      return null;
    },
    containerHistoryExtended_Fill() {
      var data = this.$store.getters["containers/getContainerExtendedHistory"];
      var propFill = this.properties.find( x=> x.propertyName=='Fill');
      return data.filter(x=> x.propertyId == propFill.propertyId);
    },
    properties(){
      return this.$store.getters["properties/getProperties"];
    }
  },
  watch: {
    containerId: function (newId, oldId) {
      this.loadHistory();
    }
  },
  methods: {
    async loadHistory(){
      this.volViewLbl = "Summary";
      this.volViewDetail = false;

      if (this.container.containerId != null && this.container.containerId !== undefined && this.container.containerId > 0)
      {
        await this.$store.dispatch("containers/loadContainerHistory", this.container.containerId);
        // await this.$store.dispatch("containers/loadContainerHistoryDetail", this.container.containerId);
        await this.$store.dispatch("containers/loadContainerHistoryExtended", this.container.containerId);
      }
      this.loading = false
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} L</span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    changeVolView(e) {
      this.volViewLbl=e.value? "Detail" : "Summary";
      console.log("EXT ");
      console.log(this.containerHistoryExtended);
      console.log(this.containerHistoryExtended_Fill);
    },
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .chartGroup {
    /* width: 85%; */
    height: 300px;
  }
</style>
