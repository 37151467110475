<template>
  <div class="pageContent">
    <DxPopup
      v-model:visible="detailsVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="800"
      :height="600"
      content-template="tank-info"
      titleTemplate="title"
    >
      <!-- :title="this.getContainerHeader()" -->
      <template #title>
        <!-- <h4>{{ this.getContainerHeaderTitle() }}</h4> -->
        <h4>{{ title }}</h4>
        <div style="white-space: pre-line; margin-left:2em; font-weight: 100; font-size: 13px; padding-bottom: 5px; ">
          <!-- {{ this.getContainerHeaderSubtitle() }} -->
          {{ subtitle }}
        </div>
      </template>
      <template #tank-info>
        <R3ContainerDetails :containerId="selectedContainer" :container="fullContainer" />
      </template> 
    </DxPopup>
  </div>
  
</template>

<script>

import R3ContainerDetails from "./container-details-v2.vue";
import { DxPopup } from 'devextreme-vue/popup';

export default {
  props: ['containerId'],
  components: {
    R3ContainerDetails,
    DxPopup,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  watch: {
    containerId: function (newId, oldId) {
      console.log("WATCH ID CHANGED", newId)
      this.openDetails(newId);
    }
  },
  data() {
    return {
      selectedContainer: 0,
      fullContainer: 0,
      detailsVisible: false,
      typeId: 1,
    };
  },
  computed: {
    title(){
      let header = this.fullContainer.containerName;
      if (header == undefined || header == null)
      {
        return '-';
      }
      return header;
      // return "title test"
    },
    subtitle(){
      const headerprops = this.$store.getters["properties/getHeaderProperties"];
      
      // var _ = headerprops.map(e => function (e){console.log(e);st+=e.propertyName + ': ' +  '\n'})
      // var st = headerprops.map(e => e.propertyName).join(': ' + '\n')
      var st = "";
      for (let i = 0; i < headerprops.length; i++) {
          st += headerprops[i].propertyName + ': ' + this.fullContainer[headerprops[i].propertyName] + '\n';
      }  
      console.log("subtitle / props", this.$store.getters["properties/getHeaderProperties"])
      console.log("subtitle / st", st)
      return st;
      // // let props = this.$store.getters["properties/getProperties"];
      // let props = this.$store.getters["properties/getProperties"];
      // console.log("SET SUB TITLE", props);
      // let body="";
      // for (let i = 0; i < props.length; i++) {
      //   if (props[i].showHeader && props[i].appliesTo == "C" && props[i].containerTypeId == this.typeId){
      //     // body += props[i].propertyName + ': ' + this.fullContainer[props[i].propertyName] + '\n';
      //     var headerProp = this.fullContainer.properties(x=>x.propertyId==props[i].propertyId).propertyName
      //     console.log(headerProp)
      //     body += props[i].propertyName + ': ' +  '\n';
      //   }
      // }  
      // return body;
      // // return "subtitle test"
    },
   },
  methods: {
    async openDetails(key) {
      console.log("OPEN DETAILS", key)
      
      await this.$store.dispatch("containers/loadContainerStatus", key);
      this.fullContainer = this.$store.getters["containers/getContainerStatus"];
      // moved up to the parent screen/obj so it doesn't keep calling every time we click on a row in grid
      // await this.$store.dispatch("properties/loadProperties");
      
      this.selectedContainer = key;
      this.detailsVisible = true;
      console.log(key, this.fullContainer);
    },
    // getContainerHeaderTitle(){
    //   console.log("SET TITLE");
    //   let header = this.fullContainer.containerName;
    //   if (header == undefined || header == null)
    //   {
    //     return '-';
    //   }
    //   return header;
    // },
    // getContainerHeaderSubtitle(){
    //   let props = this.$store.getters["properties/getProperties"];
    //   console.log("SET SUB TITLE");
    //   let body="";
    //   for (let i = 0; i < props.length; i++) {
    //     if (props[i].showHeader && props[i].appliesTo == "C" && props[i].containerTypeId == this.typeId){
    //       // body += props[i].propertyName + ': ' + this.fullContainer[props[i].propertyName] + '\n';
    //       // var headerProp = this.fullContainer.properties(x=>x.propertyId==props[i].propertyId).propertyName
    //       body += props[i].propertyName + ': ' +  '\n';
    //     }
    //   }  
    //   return body;
    // },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    async loadPageData() {
      
    },
  },
};

</script>

<style scoped>
  .pageContent {
    /* max-height: 40vh; */
    /* overflow: scroll; */
  }
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  .subtitle pre {
    white-space: pre-wrap; 
    word-wrap: break-word;
    font-family: inherit;
  }
</style>
