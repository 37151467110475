export  default {
    setContracts(state, payload) {
        state.contracts = payload;
    },
    updateContract(state, payload) {
        const contract = state.contracts.find(x=>x.contractId===payload.contractId);
        contract.contractName = payload.contractName;
        contract.defaultLength = payload.defaultLength;
    },
    addContract(state, payload) {
        state.contracts.push(payload);
    },
    setCurrentContract(state, payload) {
        state.currentContract = payload;
    },
    setResponseCode(state, payload) {
        state.responseCode = payload;
    }
};