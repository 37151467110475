export  default {
    getContracts(state) {
        return state.contracts;
    },
    getCurrentContract(state) {
        return state.currentContract;
    },
    getResponseCode(state) {
        return state.responseCode;
    },
};